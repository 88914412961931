<template>
  <AuthLayout>
    <template v-slot:authContent>
      <!-- login page start -->
      <section id="auth-login" class="row flexbox-container">
        <div class="col-xl-8 col-11">
          <div class="card bg-authentication mb-0">
            <div class="row m-0">
              <!-- left section-login -->
              <div class="col-md-6 col-12 px-0">
                <div class="card disable-rounded-right mb-0 p-2 h-100 d-flex justify-content-center">
                  <div class="card-header pb-1">
                    <div class="card-title">
                      <h4 class="text-center mb-2">Welcome, Please Login</h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <form @submit.prevent="submitLogin">
                      <div class="form-group mb-50">
                        <label class="text-bold-600" for="inputUsername">Username</label>
                        <input type="text" class="form-control" id="inputUsername"
                               placeholder="Enter email or phone number." v-model="postLoginData.username"/>
                        <div class="text-danger" v-if="errors.username">{{ errors.username[0] }}</div>
                      </div>
                      <div class="form-group">
                        <label class="text-bold-600" for="inputPassword">Password</label>
                        <input type="password" class="form-control" id="inputPassword" placeholder="Password"
                               v-model="postLoginData.password"/>
                      </div>
                      <div class="form-group d-flex flex-md-row flex-column justify-content-between align-items-center">
                        <div class="text-left">
                          <div class="checkbox checkbox-sm">
                            <input type="checkbox" class="form-check-input" id="franchiseeFlag"
                                   v-model="franchiseeFlag">
                            <label for="franchiseeFlag"><small>I am a Franchisee</small></label>
                          </div>
                        </div>
                        <div class="text-right">
                          <router-link :to="{name:'appForgotPassword'}" class="card-link"><small>Forgot
                            Password?</small></router-link>
                        </div>
                      </div>
                      <button type="submit" class="btn btn-primary glow w-100 position-relative">Login<i id="icon-arrow"
                                                                                                         class="bx bx-right-arrow-alt"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <!-- right section image -->
              <div class="col-md-6 d-md-block d-none text-center align-self-center p-3">
                <img class="img-fluid" :src="loginPageImage" alt="branding logo">
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- login page ends -->
    </template>
  </AuthLayout>
</template>

<script>
// components
import AuthLayout from '@/layouts/backEnd/AuthLayout.vue';
// core packages
import {mapGetters, mapActions} from 'vuex';
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  name: "login",

  mixins: [ShowToastMessage, Loader],

  components: {
    AuthLayout
  },

  props: ['initToastDataMessage', 'initToastDataType'],

  data() {
    return {
      loginPageImage: '/app-assets/images/pages/login.png',
      franchiseeFlag: false,
      postLoginData: {
        username: '',
        password: '',
        device_name: '',
        franchisee_flag: 0,
      },
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute'
    }),
  },
  watch: {
    franchiseeFlag(franchiseeFlag) {
      this.postLoginData.franchisee_flag = franchiseeFlag ? 1 : 0;
    },
  },
  methods: {
    ...mapActions({
      logIn: 'appAuthLogin/logIn',
    }),
    async deviceName() {
      let navigatorAppVersion = navigator.appVersion;
      let navigatorUserAgent = navigator.userAgent;

      let osName = 'Unknown OS';
      let browserName = navigator.appName;

      // In Opera 15+, the true version is after "OPR/"
      if (navigatorUserAgent.indexOf("OPR/") !== -1) {
        browserName = "Opera";
      }
      // In older Opera, the true version is after "Opera" or after "Version"
      else if (navigatorUserAgent.indexOf("Opera") !== -1) {
        browserName = "Opera";
      }
      // In MSIE, the true version is after "MSIE" in userAgent
      else if (navigatorUserAgent.indexOf("MSIE") !== -1) {
        browserName = "Microsoft Internet Explorer";
      }
      // In Chrome, the true version is after "Chrome"
      else if (navigatorUserAgent.indexOf("Chrome") !== -1) {
        browserName = "Chrome";
      }
      // In Safari, the true version is after "Safari" or after "Version"
      else if (navigatorUserAgent.indexOf("Safari") !== -1) {
        browserName = "Safari";
      }
      // In Firefox, the true version is after "Firefox"
      else if (navigatorUserAgent.indexOf("Firefox") !== -1) {
        browserName = "Firefox";
      }
      // In most other browsers, "name/version" is at the end of userAgent
      else if ((navigatorUserAgent.lastIndexOf(' ') + 1) < (navigatorUserAgent.lastIndexOf('/'))) {
        browserName = navigatorUserAgent.substring(navigatorUserAgent.lastIndexOf(' ') + 1, navigatorUserAgent.lastIndexOf('/'));

        if (browserName.toLowerCase() === browserName.toUpperCase()) {
          browserName = navigator.appName;
        }
      }

      if (navigatorAppVersion.indexOf("Win") !== -1) osName = "Windows";
      if (navigatorAppVersion.indexOf("Mac") !== -1) osName = "MacOS";
      if (navigatorAppVersion.indexOf("X11") !== -1) osName = "UNIX";
      if (navigatorAppVersion.indexOf("Linux") !== -1) osName = "Linux";

      return `${browserName}-${osName}`;
    },

    async submitLogin() {
      await this.loader(true);
      await this.logIn(this.postLoginData).then(async (response) => {
        await this.loader(false);
        if (response.message) {
          await this.showToastMessage(response);
        }

        if (response.status === 200) {
          if (response.errors) {
            this.errors = response.errors;
          }

          if (this.previousRoute) {
            await this.$router.push({...this.previousRoute});
          }

          if (!this.previousRoute) {
            await this.$router.push({name: 'appDashboard',});
          }
        }
      });
    },
  },
  async mounted() {
    await this.loader(true);
    if (this.initToastDataMessage) {
      await this.showToastMessage({message: this.initToastDataMessage, type: this.initToastDataType});
    }
    this.postLoginData.device_name = await this.deviceName();
    await this.loader(false);
  }
}
</script>
